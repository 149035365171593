import React from "react"
import Top from "../pageComponents/Top"

import Layout from "../components/layout"
import SEO from "../components/seo"

export interface PageProp {
  location: Location
}

const IndexPage: React.FC<PageProp> = ({ location }) => {
  return (
    <Layout location={location}>
      <SEO
        title="株式会社バンテージポイント"
        description="大手コンサルティング会社・金融・外資系事業会社に特化した、バイリンガル人材の転職を支援するキャリアコンサルティングファーム。バンテージポイントは、単に転職先を紹介するのではなく、候補者様のご経験、ご志向に相応しいキャリアを紹介し、５年先、10年先、20年先に描く理想的なキャリア到達に向けた戦略的なキャリアパスのサポートを行っています。"
      />
      <Top />
    </Layout>
  )
}

export default IndexPage
